.content--pillar-special-report:not(.content--type-immersive):not(.content--media):not(.content--type-splash) {
    background-color: #eff1f2;

    .content__headline--byline .tone-colour {
        background-color: $highlight-main;
    }

    .content__series-label .content__label__link {
        box-shadow: -3px 0 0 0 $highlight-main, 3px 0 0 0 $highlight-main;

        @include mq(leftCol) {
            line-height: 24px;
        }

        .label__link-wrapper {
            background: $highlight-main;
            position: relative;
        }
    }

    .badge-slot {
        @include mq(leftCol) {
            margin-left: -3px;
        }
    }

    .content__labels {
        @include mq(leftCol) {
            margin-bottom: $gs-baseline / 2;
            padding-left: 3px;
        }
    }
}

.content--immersive.content--pillar-special-report:not(.immersive-main-media__gallery):not(.paid-content):not(.content--gallery):not(.content--minute-article) {
    .content__labels {
        background-color: $highlight-main;

        .content__series-label__link {
            color: $brightness-7;
        }
    }

    .immersive-main-media__headline-container .content__main-column,
    .immersive-main-media__headline-container::after {
        background-color: $special-report-dark;
    }
}

.content--pillar-special-report.content--media:not(.paid-content) {
    background-color: darken($special-report-dark, 7%);
}
