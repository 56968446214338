.menu {
    background-color: $brand-main;
    box-sizing: border-box;
    font-size: 20px;
    left: 0;
    line-height: 1;
    margin-right: 6px + ($veggie-burger / 2);
    padding-bottom: $gs-baseline * 2;
    top: 0;
    z-index: $zindex-main-menu;

    @include mq($until: desktop) {
        @include menu-animation(-110%);
        -webkit-overflow-scrolling: touch;
        box-shadow: 3px 0 16px rgba(0, 0, 0, .4);
        bottom: 0;
        height: 100%;
        overflow: auto;
        padding-top: $gs-baseline / 2;
        position: fixed;
        right: 0;
        transition: transform .2s cubic-bezier(.23, 1, .32, 1);
        will-change: transform;
    }

    @include mq(mobileMedium) {
        margin-right: 8px + ($veggie-burger / 2);
    }

    @include mq(mobileLandscape) {
        margin-right: $gs-gutter + ($veggie-burger / 2);
    }

    @include mq(desktop) {
        display: none;
        position: absolute;
        padding-bottom: 0;
        padding-top: 0;
        top: 100%;
        // Left, right and width values are fallback for if vw is not supported
        left: 0;
        right: 0;
        width: 100%;

        @supports (width: 100vw) {
            // based on https://css-tricks.com/full-width-containers-limited-width-parents/#article-header-id-6
            left: 50%;
            right: 50%;
            width: 100vw;
            margin-left: -50vw;
            margin-right: -50vw;
        }

        .new-header--slim & {
            top: $pillar-height;
        }
    }

    .new-header--open & {
        @include mq($until: desktop) {
            @include menu-animation(0%);
        }

        @include mq(desktop) {
            display: block;
        }
    }

    // Don't show menu on opera mini: https://wp-mix.com/css-target-opera/
    x:-o-prefocus & {
        display: none;
    }
}

.menu__inner {
    box-sizing: border-box;

    @include mq($until: desktop) {
        &.gs-container {
            max-width: none;
        }
    }

    @include mq(desktop) {
        background-color: $brand-main;
        border: 1px solid $brand-pastel;
        border-bottom: 0;
        border-top: 0;
        box-sizing: border-box;
        padding: 0 $gs-gutter;

        .new-header--slim & {
            border-color: transparent;
        }
    }
}

.menu__overlay {
    background-color: hsla(0, 0%, 0%, .5);
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    transition: opacity .2s cubic-bezier(.23, 1, .32, 1);
    // Without this, in Safari/IOS this element appears above the menu
    width: 0;
    z-index: $zindex-main-menu - 1;

    .new-header--open & {
        opacity: 1;
        width: 100%;
    }

    @include mq(desktop) {
        display: none;
    }
}
