.media-primary {
    position: relative;
}

.content__main-column--article {
    min-height: gs-height(6);
}

.article-elongator {
    position: relative;

    .i-center {
        background-color: $brand-main;
    }
}

.after-article {
    position: relative;
    display: block;
    content: ' ';
    margin-top: $gs-baseline*2;
}
