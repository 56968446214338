@import 'social-icons';

/*doc
---
title: Social
name: social
category: Common
---

```html_example
<ul class="social u-unstyled u-cf">
    <li class="social__item">
        <a class="social__action social-icon-wrapper" href="#">
            <span class="u-h">Share via Email</span>
            <span class="social-icon"><i class="i-share-email--white i"></i></span>
        </a>
    </li>
    <li class="social__item">
        <a class="social__action social-icon-wrapper" href="#">
            <span class="u-h">Share on Facebook</span>
            <span class="social-icon"><i class="i-share-facebook--white i"></i></span>
        </a>
    </li>
    <li class="social__item">
        <a class="social__action social-icon-wrapper" href="#">
            <span class="u-h">Share on Twitter</span>
            <span class="social-icon"><i class="i-share-twitter--white i"></i></span>
        </a>
    </li>
</ul>
```
*/

.social__item {
    float: left;
    min-width: 32px;
    padding: 0 3px $gs-baseline/2 0;
    cursor: pointer;
}

.social__item--whatsapp {
    @include mq(tablet) {
        display: none;
    }
}

.social__item--messenger {
    @include mq(tablet) {
        display: none;
    }
}

.social__action {
    display: inline-block;
}

/**
 * Social icons
 * Usage:
 * <element class="social-icon-wrapper">
 *     <span class="social-icon">
 *         <i class="i share-icon-xxx"></i>
 *     </span>
 * </element>
 * <element class="social-icon-wrapper">
 *     <span class="social-icon social-icon--small">
 *         <i class="i share-icon-xxx"></i>
 *         <span class="social-icon__label">Share on xxx</span>
 *     </span>
 * </element>
 */

.social-icon {
    @extend %icon-holder-circle;
    @include ellipsis();
    border: 0;
    min-width: 32px;
    max-width: 100%;
    width: auto;
    height: 32px;

    &,
    &:hover,
    &:active,
    &:focus {
        color: #ffffff;
        text-decoration: none;
    }

    svg {
        height: 88%;
        width: 88%;
    }

    .social-icon__label {
        @include fs-textSans(2);
        margin: 0 .8em 0 -.5em;
    }
}

.social-icon--small {
    min-width: 24px;
    height: 24px;

    i {
        width: 24px;
        background-size: 100% !important;
    }

    .social-icon__label {
        @include fs-textSans(1);
        margin: 0 .7em 0 -.3em;
    }
}

.social-icon__label {
    white-space: nowrap;
    font-weight: bold;
}

.selection-sharing {
    position: absolute;
    margin-top: -$gs-baseline * 3;
    top: 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity .15s ease;
    z-index: $zindex-content;

    .social__item {
        padding: 0 $gs-gutter / 10;
        width: 32px;
    }

    .inline-icon svg {
        width: 80%;
        display: block;
    }
}

.selection-sharing.selection-sharing--active {
    visibility: visible;
    opacity: 1;
}

.share-text {
    @include fs-textSans(3);
    float: left;
    font-weight: 700;
    padding: ($gs-baseline/3*2) ($gs-gutter/10) 0 ($gs-gutter/2);
}

// Pintrest is inappropriate in certain sections

.social__item--pinterest {
    .content[class*='-network'] &,
    .content[class*='-leadership'] &,
    .content[class*='-careers'] &,
    .content[class*='-advertisement-feature'] &,
    .content[class*='-partner-zone'] & {
        display: none;
    }
}
.paid-content, .paid-content.tonal--tone-media {
    .social-icon,
    .block-share__item {
        border: 1px solid $brightness-60;
        &:hover,
        &:active,
        &:focus {
            border: 1px solid $brightness-7;
            background-color: $brightness-7;
        }
        svg {
            fill: $brightness-7;
        }
        &:focus,
        &:hover {
            svg {
                fill: $brightness-100;
            }
        }
    }
}

// prevent yank and spacefinder issue when embedded tweets resize
.tweet-main-image {
    width: 100%;
}
