// THIS FILE WAS AUTOMATICALLY GENERATED BY
// tools/__tasks__/compile/images/icons.js
// DO NOT EDIT IT!

@if ($svg-support) {
    %svg-i-camera-black-large,
    .svg-i-camera-black-large {
background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxMyI+PHBhdGggZmlsbD0iIzMzMyIgZD0iTTE4IDMuNXY4TDE2LjUgMTNoLTE1TDAgMTEuNXYtOEwxLjUgMkg1bDItMmg0bDIgMmgzLjVMMTggMy41ek05IDExYzEuOSAwIDMuNS0xLjYgMy41LTMuNVMxMC45IDQgOSA0IDUuNSA1LjYgNS41IDcuNSA3LjEgMTEgOSAxMXoiLz48L3N2Zz4=);
background-position: 0 0;
width: 18px;
height: 13px;
    }
    .svg .i-camera-black-large {
@extend %svg-i-camera-black-large !optional;
    }
    
    %svg-i-volume-mute--tone-media,
    .svg-i-volume-mute--tone-media {
background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNyIgaGVpZ2h0PSIxMyI+PHBhdGggZmlsbD0iI2ZiMCIgZD0iTTEgNEwwIDV2M2wxIDFoMmw0IDRoMVYwSDdMMyA0SDF6bTE2LS40bC0uNi0uNi0yLjkgMi42TDEwLjYgM2wtLjYuNiAyLjYgMi45TDEwIDkuNGwuNi42IDIuOS0yLjYgMi45IDIuNi42LS42LTIuNi0yLjlMMTcgMy42eiIvPjwvc3ZnPg==);
background-position: 0 0;
width: 17px;
height: 13px;
    }
    .svg .i-volume-mute--tone-media {
@extend %svg-i-volume-mute--tone-media !optional;
    }
    
    %svg-i-volume-mute--tone-podcast,
    .svg-i-volume-mute--tone-podcast {
background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNyIgaGVpZ2h0PSIxMyI+PHBhdGggZmlsbD0iI2FhZDhmMSIgZD0iTTEgNEwwIDV2M2wxIDFoMmw0IDRoMVYwSDdMMyA0SDF6bTE2LS40bC0uNi0uNi0yLjkgMi42TDEwLjYgM2wtLjYuNiAyLjYgMi45TDEwIDkuNGwuNi42IDIuOS0yLjYgMi45IDIuNi42LS42LTIuNi0yLjlMMTcgMy42eiIvPjwvc3ZnPg==);
background-position: 0 0;
width: 17px;
height: 13px;
    }
    .svg .i-volume-mute--tone-podcast {
@extend %svg-i-volume-mute--tone-podcast !optional;
    }
    
    %svg-i-volume-mute-white,
    .svg-i-volume-mute-white {
background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNyIgaGVpZ2h0PSIxMyI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTEgNEwwIDV2M2wxIDFoMmw0IDRoMVYwSDdMMyA0SDF6bTE2LS40bC0uNi0uNi0yLjkgMi42TDEwLjYgM2wtLjYuNiAyLjYgMi45TDEwIDkuNGwuNi42IDIuOS0yLjYgMi45IDIuNi42LS42LTIuNi0yLjlMMTcgMy42eiIvPjwvc3ZnPg==);
background-position: 0 0;
width: 17px;
height: 13px;
    }
    .svg .i-volume-mute-white {
@extend %svg-i-volume-mute-white !optional;
    }
    
    %svg-i-volume-low-black,
    .svg-i-volume-low-black {
background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMDUgMjA1Ij48cGF0aCBmaWxsPSIjMzMzIiBkPSJNOTkgMjMuM3YxNThoLTguN2wtNDcuNy00N2gtMzNsLTcuNi04Vjc4LjZsOC43LTguM2gzMmw0Ny00N2g4LjdtNTIgNzlsLTI5LjktMzQgNi40LTYuNCAzMy43IDMwIDM0LTMwIDYuNCA2LjQtMzAgMzQgMzAgMzMuNy02LjQgNi40LTM0LTI5LjktMzMuNyAyOS45LTYuNC02LjQgMjkuOS0zMy43Ii8+PC9zdmc+);
background-position: 0 0;
width: 0px;
height: 0px;
    }
    .svg .i-volume-low-black {
@extend %svg-i-volume-low-black !optional;
    }
    
    %svg-i-expand--black,
    .svg-i-expand--black {
background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMDUgMjA1Ij48cGF0aCBmaWxsPSIjMzMzIiBkPSJNNzIuOCAxOTF2MTEuM2gtNjZMMi4xIDE5OHYtNjZoMTEuN2w1LjEgNDAuNiA1Mi01MiAxMi45IDEyLjktNTIgNTIgNDAuNiA1LjVtNTktMTc3VjIuN2g2Nmw0LjMgNC4zdjY2aC0xMS4zbC01LjUtNDEtNTIgNTItMTIuNS0xMi45IDUyLTUyLTQwLjYtNS4xIi8+PC9zdmc+);
background-position: 0 0;
width: 0px;
height: 0px;
    }
    .svg .i-expand--black {
@extend %svg-i-expand--black !optional;
    }
    
    %svg-i-volume-high--tone-media,
    .svg-i-volume-high--tone-media {
background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxMyI+PHBhdGggZmlsbD0iI2ZiMCIgZD0iTTMgNEgxTDAgNXYzbDEgMWgybDQgNGgxVjBIN0wzIDR6bTExLjcgMi41YzAgMi0uNyAzLjgtMS44IDUuMmwuNC40YzEuNi0xLjMgMi42LTMuMyAyLjYtNS42cy0xLTQuMy0yLjYtNS42bC0uNC40YzEuMiAxLjUgMS44IDMuMyAxLjggNS4ybS0zLjcgMGMwIDEuMS0uMyAyLjItLjkgMy4xbC41LjVjLjgtMSAxLjQtMi4yIDEuNC0zLjZzLS42LTIuNi0xLjUtMy41bC0uNS41Yy42LjggMSAxLjkgMSAzIi8+PC9zdmc+);
background-position: 0 0;
width: 16px;
height: 13px;
    }
    .svg .i-volume-high--tone-media {
@extend %svg-i-volume-high--tone-media !optional;
    }
    
    %svg-i-volume-high--tone-podcast,
    .svg-i-volume-high--tone-podcast {
background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxMyI+PHBhdGggZmlsbD0iI2FhZDhmMSIgZD0iTTMgNEgxTDAgNXYzbDEgMWgybDQgNGgxVjBIN0wzIDR6bTExLjcgMi41YzAgMi0uNyAzLjgtMS44IDUuMmwuNC40YzEuNi0xLjMgMi42LTMuMyAyLjYtNS42cy0xLTQuMy0yLjYtNS42bC0uNC40YzEuMiAxLjUgMS44IDMuMyAxLjggNS4ybS0zLjcgMGMwIDEuMS0uMyAyLjItLjkgMy4xbC41LjVjLjgtMSAxLjQtMi4yIDEuNC0zLjZzLS42LTIuNi0xLjUtMy41bC0uNS41Yy42LjggMSAxLjkgMSAzIi8+PC9zdmc+);
background-position: 0 0;
width: 16px;
height: 13px;
    }
    .svg .i-volume-high--tone-podcast {
@extend %svg-i-volume-high--tone-podcast !optional;
    }
    
    %svg-i-volume-high-black,
    .svg-i-volume-high-black {
background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxMyI+PHBhdGggZmlsbD0iIzMzMyIgZD0iTTMgNEgxTDAgNXYzbDEgMWgybDQgNGgxVjBIN0wzIDR6bTExLjcgMi41YzAgMi0uNyAzLjgtMS44IDUuMmwuNC40YzEuNi0xLjMgMi42LTMuMyAyLjYtNS42cy0xLTQuMy0yLjYtNS42bC0uNC40YzEuMiAxLjUgMS44IDMuMyAxLjggNS4ybS0zLjcgMGMwIDEuMS0uMyAyLjItLjkgMy4xbC41LjVjLjgtMSAxLjQtMi4yIDEuNC0zLjZzLS42LTIuNi0xLjUtMy41bC0uNS41Yy42LjggMSAxLjkgMSAzIi8+PC9zdmc+);
background-position: 0 0;
width: 16px;
height: 13px;
    }
    .svg .i-volume-high-black {
@extend %svg-i-volume-high-black !optional;
    }
    
    %svg-i-volume-high-white,
    .svg-i-volume-high-white {
background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxMyI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTMgNEgxTDAgNXYzbDEgMWgybDQgNGgxVjBIN0wzIDR6bTExLjcgMi41YzAgMi0uNyAzLjgtMS44IDUuMmwuNC40YzEuNi0xLjMgMi42LTMuMyAyLjYtNS42cy0xLTQuMy0yLjYtNS42bC0uNC40YzEuMiAxLjUgMS44IDMuMyAxLjggNS4ybS0zLjcgMGMwIDEuMS0uMyAyLjItLjkgMy4xbC41LjVjLjgtMSAxLjQtMi4yIDEuNC0zLjZzLS42LTIuNi0xLjUtMy41bC0uNS41Yy42LjggMSAxLjkgMSAzIi8+PC9zdmc+);
background-position: 0 0;
width: 16px;
height: 13px;
    }
    .svg .i-volume-high-white {
@extend %svg-i-volume-high-white !optional;
    }
    
    %svg-i-volume-low--tone-media,
    .svg-i-volume-low--tone-media {
background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMyI+PHBhdGggZmlsbD0iI2ZiMCIgZD0iTTMgNEgxTDAgNXYzbDEgMWgybDQgNGgxVjBIN0wzIDR6bTggMi41YzAgMS4xLS4zIDIuMi0uOSAzLjFsLjUuNWMuOC0xIDEuNC0yLjIgMS40LTMuNnMtLjYtMi42LTEuNS0zLjVsLS41LjVjLjYuOCAxIDEuOSAxIDMiLz48L3N2Zz4=);
background-position: 0 0;
width: 12px;
height: 13px;
    }
    .svg .i-volume-low--tone-media {
@extend %svg-i-volume-low--tone-media !optional;
    }
    
    %svg-i-volume-low--tone-podcast,
    .svg-i-volume-low--tone-podcast {
background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMyI+PHBhdGggZmlsbD0iI2FhZDhmMSIgZD0iTTMgNEgxTDAgNXYzbDEgMWgybDQgNGgxVjBIN0wzIDR6bTggMi41YzAgMS4xLS4zIDIuMi0uOSAzLjFsLjUuNWMuOC0xIDEuNC0yLjIgMS40LTMuNnMtLjYtMi42LTEuNS0zLjVsLS41LjVjLjYuOCAxIDEuOSAxIDMiLz48L3N2Zz4=);
background-position: 0 0;
width: 12px;
height: 13px;
    }
    .svg .i-volume-low--tone-podcast {
@extend %svg-i-volume-low--tone-podcast !optional;
    }
    
    %svg-i-volume-low-white,
    .svg-i-volume-low-white {
background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMyI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTMgNEgxTDAgNXYzbDEgMWgybDQgNGgxVjBIN0wzIDR6bTggMi41YzAgMS4xLS4zIDIuMi0uOSAzLjFsLjUuNWMuOC0xIDEuNC0yLjIgMS40LTMuNnMtLjYtMi42LTEuNS0zLjVsLS41LjVjLjYuOCAxIDEuOSAxIDMiLz48L3N2Zz4=);
background-position: 0 0;
width: 12px;
height: 13px;
    }
    .svg .i-volume-low-white {
@extend %svg-i-volume-low-white !optional;
    }
    
    %svg-i-embed--black,
    .svg-i-embed--black {
background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAyNSAxNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNy4xIDEwLjJWOC40TDIuOCA2LjcgNy4xIDVWMy4yTC44IDZ2MS41bDYuMyAyLjhNOS42IDE0aDEuN0wxNS4xLjRoLTEuN0w5LjYgMTRtOC40LTMuN2w2LjMtMi44VjZMMTggMy4yVjVsNC4zIDEuN0wxOCA4LjR2MS44IiBmaWxsPSIjMzMzIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=);
background-position: 0 0;
width: 25px;
height: 14px;
    }
    .svg .i-embed--black {
@extend %svg-i-embed--black !optional;
    }
    
    %svg-i-embed--tone-media,
    .svg-i-embed--tone-media {
background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAyNSAxNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNy4xMiAxMC4yNFY4LjQzMkwyLjgxNiA2LjcwNCA3LjEyIDQuOTkyVjMuMTY4TC44IDUuOTUydjEuNTA0bDYuMzIgMi43ODR6bTIuNDc2IDMuNjhoMS43NDRMMTUuMTguMjcyaC0xLjc0NEw5LjU5NiAxMy45MnptOC4zNjQtMy42OGw2LjMyLTIuNzg0VjUuOTUybC02LjMyLTIuNzg0djEuODI0bDQuMzA0IDEuNzI4LTQuMzA0IDEuNzEydjEuODA4eiIgZmlsbD0iI2ZiMCIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+);
background-position: 0 0;
width: 25px;
height: 14px;
    }
    .svg .i-embed--tone-media {
@extend %svg-i-embed--tone-media !optional;
    }
    
    %svg-i-embed--white,
    .svg-i-embed--white {
background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAyNSAxNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNy4xMiAxMC4yNFY4LjQzMkwyLjgxNiA2LjcwNCA3LjEyIDQuOTkyVjMuMTY4TC44IDUuOTUydjEuNTA0bDYuMzIgMi43ODR6bTIuNDc2IDMuNjhoMS43NDRMMTUuMTguMjcyaC0xLjc0NEw5LjU5NiAxMy45MnptOC4zNjQtMy42OGw2LjMyLTIuNzg0VjUuOTUybC02LjMyLTIuNzg0djEuODI0bDQuMzA0IDEuNzI4LTQuMzA0IDEuNzEydjEuODA4eiIgZmlsbD0iI0ZGRiIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+);
background-position: 0 0;
width: 25px;
height: 14px;
    }
    .svg .i-embed--white {
@extend %svg-i-embed--white !optional;
    }
    
    %svg-i-expand-black,
    .svg-i-expand-black {
background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIyMiI+PHBhdGggZmlsbD0iIzMzMyIgZD0iTTMuNCAyMC4yTDkgMTQuNSA3LjUgMTNsLTUuNyA1LjZMMSAxNEgwdjcuNWwuNS41SDh2LTFsLTQuNi0uOE0xOC43IDEuOUwxMyA3LjYgMTQuNCA5bDUuNy01LjcuNSA0LjdoMS4yVi42bC0uNS0uNUgxNHYxLjJsNC43LjYiLz48L3N2Zz4=);
background-position: 0 0;
width: 22px;
height: 22px;
    }
    .svg .i-expand-black {
@extend %svg-i-expand-black !optional;
    }
    
    %svg-i-expand-white,
    .svg-i-expand-white {
background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIyMiI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTMuNCAyMC4yTDkgMTQuNSA3LjUgMTNsLTUuNyA1LjZMMSAxNEgwdjcuNWwuNS41SDh2LTFsLTQuNi0uOE0xOC43IDEuOUwxMyA3LjYgMTQuNCA5bDUuNy01LjcuNSA0LjdoMS4yVi42bC0uNS0uNUgxNHYxLjJsNC43LjYiLz48L3N2Zz4=);
background-position: 0 0;
width: 22px;
height: 22px;
    }
    .svg .i-expand-white {
@extend %svg-i-expand-white !optional;
    }
    
    %svg-i-expand-yellow,
    .svg-i-expand-yellow {
background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIyMiI+PHBhdGggZmlsbD0iI2ZiMCIgZD0iTTMuNCAyMC4yTDkgMTQuNSA3LjUgMTNsLTUuNyA1LjZMMSAxNEgwdjcuNWwuNS41SDh2LTFsLTQuNi0uOE0xOC43IDEuOUwxMyA3LjYgMTQuNCA5bDUuNy01LjcuNSA0LjdoMS4yVi42bC0uNS0uNUgxNHYxLjJsNC43LjYiLz48L3N2Zz4=);
background-position: 0 0;
width: 22px;
height: 22px;
    }
    .svg .i-expand-yellow {
@extend %svg-i-expand-yellow !optional;
    }
    
    %svg-i-video-camera--tone-media,
    .svg-i-video-camera--tone-media {
background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNiIgaGVpZ2h0PSIyMyI+PHBhdGggZmlsbD0iI2ZiMCIgZD0iTTMgMEwwIDN2MTdsMyAzaDE5VjBIM20yMiA5djVsOSA4aDJWMWgtMmwtOSA4eiIvPjwvc3ZnPg==);
background-position: 0 0;
width: 36px;
height: 23px;
    }
    .svg .i-video-camera--tone-media {
@extend %svg-i-video-camera--tone-media !optional;
    }
    
    %svg-i-video-camera--tone-podcast,
    .svg-i-video-camera--tone-podcast {
background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNiIgaGVpZ2h0PSIyMyI+PHBhdGggZmlsbD0iI2FhZDhmMSIgZD0iTTMgMEwwIDN2MTdsMyAzaDE5VjBIM20yMiA5djVsOSA4aDJWMWgtMmwtOSA4eiIvPjwvc3ZnPg==);
background-position: 0 0;
width: 36px;
height: 23px;
    }
    .svg .i-video-camera--tone-podcast {
@extend %svg-i-video-camera--tone-podcast !optional;
    }
    
    %svg-i-close-icon-white-small,
    .svg-i-close-icon-white-small {
background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMCIgaGVpZ2h0PSIzMCI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTIxIDkuOGwtLjgtLjgtNS4yIDQuOEw5LjggOWwtLjguOCA0LjggNS4yTDkgMjAuMmwuOC44IDUuMi00LjggNS4yIDQuOC44LS44LTQuOC01LjJMMjEgOS44Ii8+PC9zdmc+);
background-position: 0 0;
width: 30px;
height: 30px;
    }
    .svg .i-close-icon-white-small {
@extend %svg-i-close-icon-white-small !optional;
    }
    
    %svg-i-pause-32--tone-media,
    .svg-i-pause-32--tone-media {
background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiI+PGNpcmNsZSBmaWxsPSIjZmIwIiBjeD0iMTYiIGN5PSIxNiIgcj0iMTYiLz48cGF0aCBmaWxsPSIjMzMzIiBkPSJNMTQgMjJoLTNWMTEuMWwxLTEuMWgydjEybTQtMTJoM3YxMC45TDIwIDIyaC0yVjEwIi8+PC9zdmc+);
background-position: 0 0;
width: 32px;
height: 32px;
    }
    .svg .i-pause-32--tone-media {
@extend %svg-i-pause-32--tone-media !optional;
    }
    
    %svg-i-pause-32--tone-podcast,
    .svg-i-pause-32--tone-podcast {
background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiI+PGNpcmNsZSBmaWxsPSIjYWFkOGYxIiBjeD0iMTYiIGN5PSIxNiIgcj0iMTYiLz48cGF0aCBmaWxsPSIjMzMzIiBkPSJNMTQgMjJoLTNWMTEuMWwxLTEuMWgydjEybTQtMTJoM3YxMC45TDIwIDIyaC0yVjEwIi8+PC9zdmc+);
background-position: 0 0;
width: 32px;
height: 32px;
    }
    .svg .i-pause-32--tone-podcast {
@extend %svg-i-pause-32--tone-podcast !optional;
    }
    
    %svg-i-play-32--tone-media,
    .svg-i-play-32--tone-media {
background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiI+PGNpcmNsZSBmaWxsPSIjZmIwIiBjeD0iMTYiIGN5PSIxNiIgcj0iMTYiLz48cGF0aCBmaWxsPSIjMzMzIiBkPSJNMjMgMTUuNjdMMTIgMTFsLS45OS4zdjkuMjU3bDEgLjMgMTEtNC42N3YtLjUiLz48L3N2Zz4=);
background-position: 0 0;
width: 32px;
height: 32px;
    }
    .svg .i-play-32--tone-media {
@extend %svg-i-play-32--tone-media !optional;
    }
    
    %svg-i-play-32--tone-podcast,
    .svg-i-play-32--tone-podcast {
background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiI+PGNpcmNsZSBmaWxsPSIjYWFkOGYxIiBjeD0iMTYiIGN5PSIxNiIgcj0iMTYiLz48cGF0aCBmaWxsPSIjMzMzIiBkPSJNMjMgMTUuNjdMMTIgMTFsLS45OS4zdjkuMjU3bDEgLjMgMTEtNC42N3YtLjUiLz48L3N2Zz4=);
background-position: 0 0;
width: 32px;
height: 32px;
    }
    .svg .i-play-32--tone-podcast {
@extend %svg-i-play-32--tone-podcast !optional;
    }
    
    %svg-i-error-exclamation,
    .svg-i-error-exclamation {
background-image: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMzYgMzYiIHdpZHRoPSIzNiIgaGVpZ2h0PSIzNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxjaXJjbGUgZmlsbD0iI2ZmZiIgY3g9IjE4IiBjeT0iMTgiIHI9IjE4Ii8+PHBhdGggZD0iTTE0LjcyNyA1LjcyN2wxLjYzNi0xLjYzNmgzLjI3M2wxLjYzNiAxLjYzNi0xLjYzNiAxNS41NDVoLTMuMjczTDE0LjcyNyA1LjcyN202LjU0NSAyMi4wOWMwLTEuOC0xLjQ3My0zLjI3My0zLjI3My0zLjI3M2EzLjI4MyAzLjI4MyAwIDAgMC0zLjI3MyAzLjI3M2MwIDEuOCAxLjQ3MyAzLjI3MyAzLjI3MyAzLjI3M3MzLjI3My0xLjQ3MyAzLjI3My0zLjI3MyIgZmlsbD0iIzMzMyIvPjwvZz48L3N2Zz4=);
background-position: 0 0;
width: 36px;
height: 36px;
    }
    .svg .i-error-exclamation {
@extend %svg-i-error-exclamation !optional;
    }
}